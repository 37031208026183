import React, { useState } from "react";
import styles from "./Fifth.module.css";
import data from "../694.json"
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Fifth = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [id, setId] = useState(1);
    return (
        <div className={styles.top1}>
            <div className={styles.top1_contents}>
                <img src="/assets/Logo.png" className={styles.logo} alt="" />
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <a className={styles.link} href={data[id]["Ar Drive Link"]} target="_blank" onClick={handleClose}>
                            Go to Drive
                        </a>
                        <a className={styles.link} href={data[id]["Openseas Link"]} target="_blank" onClick={handleClose}>
                            Go to OpenSea
                        </a>
                    </div>
                </Box>
            </Modal>

            <div className={styles.contant}>
                <div className={styles.box}>Sample NFT Gallary</div>
                <div className={styles.button_container}>
                    {
                        data.map((data, i) => (
                            <div className={styles.button} onClick={() => {
                                handleOpen()
                                setId(i);
                            }} >
                                {i + 1}
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default Fifth;
