import React from "react";
import styles from "./Six.module.css";

const Forth = () => {
  return (
    <div className={styles.top1}>
      <div className={styles.top1_contents}>
        <img src="/assets/Logo.png" className={styles.logo} alt="" />
      </div>
      <h1 className={styles.hn}>Special Airdrop</h1>

      <div className={styles.contant}>
        <h2>Offer :- Receive 1 Free Airdrop for every 5 NFTs purchased</h2>
        <h3>How to claim Your Airdrop:</h3>
        <p>
          1. If you qualify for a Free Airdrop, email your purchase transaction details
          for the entire month, including your wallet address, to
          info@ProofofGods.com.
        </p>
        <p>2. Include a request for your Airdrop NFT claim in the email</p>
        <p>
          3. Airdrops for a given month will be dispatched in the following
          month.
        </p>

        <p>
          4. To participate, donate 11 Matic per Airdrop NFT claimed to the
          Proof-of-Gods Mission wallet: {""}
          <span className="wallet_address">
            0xC4AE05f5eFA7729455E92a7D96f0f184B5Dc3146.
          </span>
        </p>
        <div className={styles.res}>
          <span>Airdrop Reserves:-</span>A total of 10,000 Proof-of-Gods NFTs
          are available for this promotion. Offer valid until the 10,000 NFT
          reserve is exhausted.
        </div>
        <h3>Submitting New Proof-of-Gods NFTs:-</h3>
        <nav>
          <p>1. Email your NFT to info@ProofofGods.com</p>
          <p>
            2. If selected, your NFT will be added to the collection and
            transferred to the wallet you provide.
          </p>
          <p>
            3. After receiving a confirmation email from info@ProofofGods.com,
            pay a listing fee of 11 Matic to the Proof-of-Gods Mission wallet: {""}
            <span className="wallet_address">
              0xC4AE05f5eFA7729455E92a7D96f0f184B5Dc3146.
            </span>
          </p>
          <p>
            4. Contributors can list their NFTs at any price of their choice.
          </p>
        </nav>
        <button className={styles.btn}>
          <a
            href=" https://opensea.io/collection/proof-of-gods-unleashed"
            target="_blank"
          >
            See More NFT
          </a>
        </button>
      </div>
    </div>
  );
};

export default Forth;
