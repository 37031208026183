import React, { useState } from "react";
import styles from "./Seven.module.css";
import data from "../973.json"
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Seven = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [id, setId] = useState(1);
    return (
        <div className={styles.top1}>
            <div className={styles.top1_contents}>
                <img src="/assets/Logo.png" className={styles.logo} alt="" />
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <a className={styles.link} href={data[id]["To Buy CLICK"]} target="_blank" onClick={handleClose}>
                            Buy
                        </a>
                        <a className={styles.link} href={data[id]["To View CLICK"]} target="_blank" onClick={handleClose}>
                            View
                        </a>
                    </div>
                </Box>
            </Modal>

            <div className={styles.contant}>
                <div className={styles.box}>Sample Airdrop Gallary</div>
                <div className={styles.button_container}>
                    {
                        data.map((data, i) => (
                            <div className={styles.button} onClick={() => {
                                handleOpen()
                                setId(i);
                            }} >
                                {i + 1}
                            </div>
                        ))
                    }
                </div>
            </div>

            <div className={styles.icons}>
                <a target="_blank" href="https://www.facebook.com/proofofgods">
                    <img src="/assets/facebook.png" alt="" />
                </a>
                <a target="_blank" href="https://x.com/proofofgods">
                    <img src="/assets/twitter.png" alt="" />
                </a>
                <a target="_blank" href="https://www.linkedin.com/company/97920389/admin/feed/posts/">
                    <img src="/assets/linkedin.png" alt="" />
                </a>
                <a target="_blank" href="https://www.instagram.com/proofofgods/">
                    <img src="/assets/instagram.png" alt="" />
                </a>
                <a target="_blank" href="https://in.pinterest.com/proofofgods/">
                    <img src="/assets/pinterest.png" alt="" />
                </a>
                <a target="_blank" href="https://www.reddit.com/user/ProofofGods/">
                    <img src="/assets/reddit.png" alt="" />
                </a>
                <a target="_blank" href="https://medium.com/@proofofgods">
                    <img src="/assets/medium.png" alt="" />
                </a>
                <a target="_blank" href="https://www.quora.com/profile/Proof-Of-Gods">
                    <img src="/assets/quora.png" alt="" />
                </a>
            </div>
        </div>
    );
};

export default Seven;
