import React from "react";
import styles from "./Forth.module.css";

const Forth = () => {
  return (
    <div className={styles.top1}>
      <div className={styles.top1_contents}>
        <img src="/assets/Logo.png" className={styles.logo} alt="" />
      </div>

      <div className={styles.contant}>
        <p>
          Shiva (Bhairavnath) and Parvati (Mahakali) are considered God of all
          Gods, holding supreme knowledge and power. They are present in all 64
          dimensions across the universe and have an infinite presence in every
          being, every atom, across multi-verses even, transcending beyond time
          and death. Mahakaal and Mahakali are incarnations of Shiva and Parvati
          that are currently active. <br />
          <br />
          Owning auspicious original live pictures of Mahakaal and Mahakali is a
          rare opportunity as a daily view of these photographs can block all
          negative energies, give victory over emotional obstacles of doubt, as
          well as help overcome restlessness, sluggishness, aversion and desire.{" "}
          <br />
          <br />
          This auspicious incident of Proof of Gods can’t exactly be replicated
          as the level of devotion, the situation, the person, place and time
          stamp will always vary and so every photograph will always be unique.
          These appearances in pictures are a result of immense devotion,
          unconditional love and unshakeable belief from their devotees.
        </p>
      </div>
    </div>
  );
};

export default Forth;
