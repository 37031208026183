import React from "react";
import styles from "./Second.module.css";

const Second = () => {
  return (
    <div className={styles.top}>
      <div className={styles.top_content}>
        <img src="/assets/Logo.png" className={styles.logo2} alt="" />
      </div>

      <div className={styles.top_section1}>
        <h1 className={styles.hd}>Key Points to Reflect On:</h1>
        <div className={styles.opt}>
          <p>
            -Do you believe in God and that some have experienced His presence?
          </p>
          <p>-Can you meditate upon Him and recognize Him if He appears?</p>
          <p>
            -Do you think recognizing divine presence is an art that can be
            practiced?
          </p>
          <p>-Would a photograph capturing divine energy be valuable to you?</p>
        </div>
        <br />
        <br />
        <span className={styles.spn}>
          If your answers are yes, exploring the Proof-of-Gods NFT collection
          might resonate with you. However, please keep in mind that investing
          in NFTs comes with risks, including the potential loss of your
          investment.
        </span>
      </div>
      <br />
      <h1 className={styles.hd}>About the Collection:</h1>
      <div className={styles.contain}>
        <div className={styles.left_side}>
          <p>
            The Proof-of-Gods collection features 49,048 photographs of Mother
            Kaali and Shri Kaal Bhairava, capturing their mystic energy in pure
            crystal white, Black and blue shades. These images aim to offer
            protection, guidance, and spiritual growth.
          </p>
          <h1 className={styles.hd}>Final Thoughts:</h1>
          <p>
            This transition marks a positive step towards embracing a new era
            where blockchain and NFTs intersect with spirituality and art. We
            believe this unique collection can become a long-term asset for
            those who value both spiritual and digital realms
          </p>
          <span className={styles.pan}>
            Step into the divine realms with Proof-of-Gods NFTs and embrace{" "}
            <br /> the blessings of the Almighty.
          </span>
        </div>
        <div className={styles.Right_side}>
          <img src="./assets/banner2.png" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Second;
