import "./App.css";
import Landing from "./components/Landing";
import Second from "./components/Second";
import Third from "./components/Third";
import Forth from "./components/Forth";
import Fifth from "./components/Fifth";
import Seven from "./components/Seven";
import Six from "./components/Six";

function App() {
  return (
    <>
      <div className="container">
        <Landing />
        <Second />
        <Third />
        <Forth />
        <Fifth />
        <Six />

        <Seven />
      </div>
    </>
  );
}

export default App;
