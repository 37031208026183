import React from "react";
import styles from "./Landing.module.css";

const Landing = () => {
  return (
    <div className={styles.header}>
      <header className={styles.header_content}>
        {/* <h1>
          Announcement: <span>Embracing a New Era: Transition from </span>
          <br />
          EraSwap Blockchain to Proof-of-Gods NFT Collection
        </h1> */}
        <img src="/assets/Logo.png" className={styles.logo1} alt="" />
      </header>
      <div className={styles.hding_cnt}>
        <h1 className={styles.hding}>
          Introducing the Proof-of-Gods NFT Collection
        </h1>
        <img src="/assets/arrow.png" alt="" />
        <nav className={styles.navs}>
          The Proof-of-Gods NFT collection is an artistic and spiritual endeavor
          aimed at capturing divine presence through photography. This
          collection is about discovering God in our surroundings, inspired by
          the experiences of countless <br /> individuals who have felt a divine
          presence.
        </nav>
      </div>

      <div className={styles.content}>
        <section className={styles.main_section}>
          <div className={styles.content_left}>
            <h2 className={styles.hs}>
              Why Consider the Proof-of-Gods NFT Collection?{" "}
            </h2>
            <nav className={styles.text}>
              <span>1. Unique and Divine Art:</span> The first NFT collection
              focused on divine art and spirituality, featuring photographs that
              aim to capture glimpses of God. <br />
              <br />
              <span>2. Spiritual Significance:</span> Each photograph is a
              testament to the devotion and belief of individuals who have
              experienced divine moments. These images hold potential spiritual
              value and the energy of divine presence. <br />
              <br />
              <span>3. Long-Term Value:</span> As the world gradually adopts
              blockchain technology, NFTs are becoming increasingly recognized.
              Owning a Proof-of-Gods NFT means holding a piece of this evolving
              digital and spiritual frontier. <br />
              <br />
              <span>4. Potential Blessings:</span> While there is no guarantee,
              the divine nature of these NFTs could bring spiritual blessings
              and positive energy to their owners.
            </nav>
          </div>

          <div className={styles.content_right}>
            <div className={styles.image_container}>
              <img src="/assets/banner1.png" alt="" />
            </div>

            {/* <div className={styles.button_group}>
              <a href="#" className={styles.PROOF}>
                GO TO PROOF OF GODS
              </a>

              <a
                href="https://opensea.io/collection/proof-of-gods-unleashed"
                target="_blank"
                className={styles.NFT}
              >
                GO TO PROOF OF GOD NFT COLLECTION
              </a>
            </div> */}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Landing;
