import React from "react";
import styles from "./Third.module.css";

const Third = () => {
  return (
    <div className={styles.top1}>
      <div className={styles.top1_contents}>
        <img src="/assets/Logo.png" className={styles.logo} alt="" />
      </div>

      <div className={styles.contant}>
        <p>
          This Unveils a digitally transferrable treasure, encapsulating the
          divine essence of Mahakaal and Mahakaali. Witness their fused divine
          presence on this canvas, bestowing wealth, peace, and blessings of
          Supreme God Shiva and Goddess Durga. Delve into their histories for
          profound impact. <br />
          <br />
          Seize the opportunity to elevate your fortune with this digital
          collectible. Mahakaal and Mahakaali, Divine God powers, personally
          bestow blessings in this era. The 5th Dimension, a realm of heavenly
          energies, manifests over twenty facets based on your thoughts and
          belief. <br />
          <br />
          This canvas is not just a photograph; it pulsates with divine energy,
          waiting to unlock manifestations of abundance and happiness through
          unwavering belief. "Proof of Gods - Unleashed" transcends, offering a
          firsthand witness to the divine presence, heralding a new era of
          spiritual enlightenment and an unparalleled connection with the
          Divine.
        </p>
      </div>
    </div>
  );
};

export default Third;
